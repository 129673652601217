import * as React from "react";
import { navigate } from "gatsby";

const NotFoundPage = () => {
  return (
    <>
      <h1>Oops!</h1>

      <p>There seems to be nothing here.</p>

      <p>
        You can either{" "}
        <a href="/" onClick={(event) => event.preventDefault() && navigate(-1)}>
          go back
        </a>
        , or use the menu to navigate the rest of the site.
      </p>
    </>
  );
};

export default NotFoundPage;
